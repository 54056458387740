.container {
    margin: 5rem 10rem 5rem 10rem;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: flex-start;
    justify-content: space-between;
    height: 100vh;
}

.divCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}


.div {
    background-image: linear-gradient(45deg, rgba(34, 193, 195, .8) 0%, rgba(89, 139, 159, 0.82) 26%, rgba(89, 139, 159, 0.85) 69%, rgba(45, 149, 253, .8) 100%),
        url('https://blogthinkbig.com/wp-content/uploads/sites/4/2018/12/tecnologia-salud.jpg?fit=1911%2C1081');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 60vh;
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
}

.div h1 {
    margin: 0;
    font-size: 3.5rem;
    padding-bottom: 1rem;
}

.div h2 {
    margin: 0 0 0px;
    font-size: 1.2rem;
    padding-bottom: 2.4rem;
    font-weight: 200;
}

.topic h2 {
    margin-top: 0;
}

@media (max-width:600px) {
    .topic {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .div {
        height: 94.12vh;
    }
}

.stepper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: aliceblue;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    color: whitesmoke;
}