.container {
    margin: 5rem 10rem 5rem 10rem;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: flex-start;
    justify-content: space-between;
    height: 100vh;
}

.divCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.divImg {
    background-image: linear-gradient(45deg, rgba(89, 124, 171, 0.8) 0%, rgba(67, 158, 176, 0.8) 100%),
        url('https://cdn.lecturio.com/assets/Featured-image-Student-Blog-Hospital-Team.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 60vh;
    font-size: 3rem;
    color: whitesmoke;
}

.div {
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    backdrop-filter: blur(3px);
    height: 60vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
}

.div h1 {
    margin: 0;
    font-size: 3.5rem;
    padding-bottom: 1rem;
}

.div h2 {
    margin: 0 0 0px;
    font-size: 1.2rem;
    padding-bottom: 2.4rem;
    font-weight: 200;
}

.topic h2 {
    margin-top: 0;
}

@media (max-width:600px) {
    .topic {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .div {
        height: 94.12vh;
    }
    .divImg {
        height: 94.12vh;
    }
}

.stepper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: aliceblue;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    color: whitesmoke;
}

.cardProfile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 2rem;
}

.imgProfile {
    height: 350px;
    object-fit: contain;
    border-radius: 1rem;
    padding-bottom: 2rem;
}