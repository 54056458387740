.container {
    margin: 5rem 10rem 5rem 10rem;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: flex-start;
    justify-content: space-between;
    height: 100vh;
}

.div {
    background-image: linear-gradient(167deg, rgba(76, 203, 185, .8) 0%, rgba(26, 76, 168, .8) 44%, rgba(63, 9, 121, .8) 94%),
        url('https://fotografias.lasexta.com/clipping/cmsimages01/2021/10/21/1D05ADDA-598A-4B77-BEC1-85248AE558E2/98.jpg?crop=6240,3511,x0,y0&width=1900&height=1069&optimize=low&format=webply');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 92.12vh;
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.div h1 {
    margin: 0;
    font-size: 3.5rem;
}

.div h2 {
    margin: 0 0 50px;
    font-size: 2.2rem;
}

.topic h2 {
    margin-top: 0;
}

@media (max-width:600px) {
    .topic {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .div {
        height: 94.12vh;
    }
}