.container {
    margin: 5rem 10rem 5rem 10rem;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: flex-start;
    justify-content: space-between;
    height: 100vh;
}

.div {
    background-image: linear-gradient(254deg, rgba(34,55,195,.8) 0%, rgba(46, 189, 178, 0.8) 100%),
        url('https://www.comunidad.madrid/sites/default/files/styles/aspect_ratio_16_9_desktop/public/img/alimentacion/dieta_ejercicio.jpg?itok=Ms7zHh1x&timestamp=1487062435');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 60vh;
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.div h1 {
    margin: 0;
    font-size: 3.5rem;
}
.div h2 {
    margin: 0 0 0px;
    font-size: 2.2rem;
    padding-bottom: 4rem;
}

.topic h2 {
    margin-top: 0;
}

@media (max-width:600px) {
    .topic {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .div {
        height: 94.12vh;
    }
}