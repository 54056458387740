.img {
    object-fit: cover;
    padding-top: 0;
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.divImg {
    width: 100%;
    height: 100%;
}

.divImgBanner {
    padding-bottom: 4rem;
    width: 100%;
    height: 50vh;
}

.container {
    margin: 5rem 10rem 5rem 10rem;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: flex-start;
    justify-content: space-between;
    height: 100vh;
}

.div {
    background-image: linear-gradient(254deg, rgba(36,90,191,.8) 0%, rgba(163, 209, 241, 0.8) 100%),
        url('https://img.freepik.com/fotos-premium/escritorio-medico-estetoscopio-teclado-computadora-pildoras-medicina-sobre-fondo-blanco-fondo-concepto-salud-o-farmacia-vista-superior_136875-2038.jpg?w=2000');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 60vh;
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.div h1 {
    margin: 0 0 .6rem 0;
    font-size: 3.5rem;
}

.div h2 {
    margin: 0 0 0;
    font-size: 2.2rem;
    padding-bottom: 4rem;
}

.topic h2 {
    margin-top: 0;
}

@media (max-width:600px) {
    .topic {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .div {
        height: 94.12vh;
    }
}